.App {
  margin: 20px auto 58px auto;
  padding-top: 20px;
  text-align: center;
  line-height: 1.6em
}

a {
  color:#333;
  background: #eee;
  text-decoration: underline;
}
a:hover {
  background:#ddd;
  color: #666
}

a.no {background:none}
a.no:hover {background:none}

button {
  margin:1em 0px !important;
}

.block {
  margin: 10px auto;
  padding:10px 10px;
  text-align: left;
  display: inline-block;
  max-width: 640px;
}

h1 {font-weight: normal}
h3 {text-transform: uppercase;letter-spacing: 5px}

.sm {
  font-size:0.8em;
  line-height: 1.6em
}

.addr {
  font-size: 1em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 70px;
  display: inline-block;
  vertical-align: middle;
  background:none;
  text-decoration: underline;
  color:#666;
}

.svg {
  vertical-align: middle;
  display: inline-block;
  margin:25px;
}

.innerSVG {
  display: inline-block;
}

.line {
  margin: 10px auto 30px auto;
  width:40%;border-bottom: 1px solid #ddd;
}

.cblock {
  margin: 10px auto;
  padding:10px 10px;
  width:320px;
  height:320px;
  text-align: center;
  display: inline-block;
}

.w3block {
  margin:20px;
}

.w3infblock {
  margin:20px auto;
  margin-bottom:40px;
  padding:20px 50px;
  display: inline-block;
  box-shadow: 0px 10px 20px rgba(0,0,0,0.6);
  border-radius: 6px;
}

.close {
  position: fixed;
  right: 10px;
  top: 10px;
  font-size: 2em;
  cursor: pointer;
}
a.close, button.close { text-decoration: none !important; background: none; border:none; margin: 0 !important; padding: 0 }

a.fuck, button.fuck { color: black; cursor:pointer; font-size: 2em; line-height: 1.2em; background: none;border:none; text-decoration: none; margin: 5px !important; padding: 10px }
a.fuck:hover, button.fuck:hover { color: white; background: black}


.full {
  position: fixed;
  left: 0px;
  top: 0px;
  text-align: center;
  background: white;
  width: 100vw;
  height: 100vh;
  z-index: 4;
  overflow: hidden;
}


footer {
  background: #f5f5f5;
  color: rgba(0,0,0,.87);
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  position: fixed;
  min-height: 32px;
  font-size: .8em;
  padding-top: 8px;
}

.hidemouse {
  cursor: none
}

footer a {background:none;color:black;padding:1px;text-decoration: underline}

@media(max-width:600px) {
  .svg.left, .svg.right {display:none}
  body {font-size: 0.9em}
  .w3infblock {  padding:15px 15px; }
  br {display:none; margin-left:20px}
}

@media(max-width:400px) {
  .svg { margin: 0px }
}
