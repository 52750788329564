body {
  margin: 0;
  padding: 0;
  font-family: "TStar", "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

button {
  font-family: "TStar", "Roboto", sans-serif;
}

@font-face {
  font-family: 'TStar';
  src: local('TStar'), url(./fonts/T-Star-medium-webfont.woff) format('woff');
}
